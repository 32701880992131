/**
 * Rounds number to given precision
 *
 * roundNumberToPrecision(10.15, 1) => 10.1
 * @param {number} value
 * @param {number} digitsAfterComma
 */
export const roundNumberToPrecision = (value: number, digitsAfterComma = 0) => {
  const toPrecision = Math.pow(10, digitsAfterComma);
  return Math.round(value * toPrecision) / toPrecision;
}
