<template>
  <div class="a-product-quantity__wrapper" @click.prevent>
    <SfOQuantitySelector
      ref="qty-input"
      class="a-product-quantity"
      :qty="value"
      :value="value"
      :max="maxQuantity"
      :measure-kg="measureKg"
      :disabled="disabled"
      :disabled-min="disabledMin"
      :product="product"
      data-transaction-name="Quantity - Change"
      @input="$emit('input', $event)"
      @blur="$v.$touch()"
      @remove="$emit('remove')"
    >
      <template #minus>
        <span class="icon-minus" />
      </template>
      <template #plus>
        <span class="icon-plus" />
      </template>
      <template #remove>
        <span class="icon-delete" />
      </template>
    </SfOQuantitySelector>
    <ALoadingSpinner
      v-show="showLoader"
      :size="20"
      :weight="3"
      :is-absolute-position="true"
    />
  </div>
</template>

<script>
import {
  minValue,
  maxValue,
  numeric,
  required
} from 'vuelidate/lib/validators';
import { onlineHelper } from '@vue-storefront/core/helpers';
import SfOQuantitySelector from 'theme/components/storefront-override/SfOQuantitySelector'
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner'
import { mapState } from 'vuex';
import DeviceType from 'theme/mixins/DeviceType';

export default {
  name: 'AProductQuantity',
  components: {
    SfOQuantitySelector,
    ALoadingSpinner
  },
  mixins: [DeviceType],
  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({})
    },
    value: {
      type: [Number, String],
      required: true
    },
    showLoader: {
      type: Boolean,
      default: false
    },
    maxQuantity: {
      type: Number,
      default: 0
    },
    minQuantityDisable: {
      type: [Number, null],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    unlimitQuantity: {
      type: Boolean,
      default: false
    },
    removeHandler: {
      type: Function,
      default: () => ({})
    },
    isQtyDecimal: {
      type: Boolean,
      default: false
    },
    measureKg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      isCartSync: state => state.cart.isSyncing,
      isCartConnecting: state => state.cart.isConnecting
    }),
    isLoading () {
      return this.isCartSync || this.isCartConnecting
    },
    isOnline () {
      return onlineHelper.isOnline;
    },
    disabledMin () {
      if (!this.minQuantityDisable) return false

      return this.minQuantityDisable >= this.value
    },
    disabled () {
      return this.isOnline && !this.unlimitQuantity ? !this.maxQuantity : false;
    },
    error () {
      const isBelowZero = this.isNumber ||
        !this.$v.value.minValue ||
        !this.$v.value.required
      if (isBelowZero) return ''
      if (isBelowZero) {
        return this.$t('Quantity must be below {quantity}', {
          quantity: this.maxQuantity
        })
      }
      return ''
    },
    isNumber () {
      return (!this.isQtyDecimal && !this.$v.value.numeric) || (this.isQtyDecimal && !this.$v.value.float)
    }
  },
  validations () {
    return {
      value: {
        minValue: minValue(1),
        maxValue: maxValue(this.maxQuantity) && this.unlimitQuantity,
        numeric,
        float: (value) => /^\d*\.?\d*$/.test(value),
        required
      }
    };
  },
  watch: {
    '$v.$invalid' () {
      this.$emit('error', this.error);
    },
    showLoader (after) {
      if (!after && !this.isMobile) {
        try {
          this.$refs?.['qty-input']?.$el?.querySelector('input')?.focus()
        } catch (e) {}
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~theme/css/fonts';

.a-product-quantity {
  display: flex;
  flex-shrink: 0;
  height: auto;
  width: 100%;
  min-width: 4.625rem;
  max-width: 5.625rem;
  background-color: transparent;
  align-items: stretch;

  &__wrapper {
    position: relative;
    width: max-content;
  }

  ::v-deep .sf-quantity-selector__button {
    &:hover {
      .icon-delete {
        &:before {
          color: var(--orange);
        }
      }
    }
  }

  ::v-deep .is-minus {
    pointer-events: none;
    &--active {
      pointer-events: initial;
    }
  }
  .icon-plus {
    width: var(--spacer-56);
    @include font-icon(var(--icon-plus));

    &:before {
      font-size: var(--font-size-16);
      color: var(--orange);
      transition: all .3s ease;
    }
  }

  .icon-minus {
    width: var(--spacer-56);
    @include font-icon(var(--icon-minus));

    &:before {
      font-size: var(--font-size-22);
      color: var(--black);
    }
  }

  .icon-delete {
    width: var(--spacer-56);
    @include font-icon(var(--icon-trash));

    &:before {
      font-size: var(--font-size-28);
      color: var(--dark-gray);
      transition: color .3s ease;
    }
  }
}

.a-product-quantity {
  margin: 0;
  max-width: 196px;
}

::v-deep {
  .sf-quantity-selector__input {
    display: flex;
    height: inherit;
    //height: 2.375rem;

    input {
      font-size: var(--font-size-13);
      padding: 0;
      border-radius: 0;
    }
  }

  .sf-quantity-selector__button:not(.is-plus) {
    background-color: rgba(229, 231, 234, 0.2);
  }
}
</style>
